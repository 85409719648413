<template>
	<div class="InviteTeam">
		<Header></Header>
		<InviteTeam></InviteTeam>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import InviteTeam from '@/components/inviteTeam/inviteTeam.vue'
	import Breadcrumb from '@/components/common/Breadcrumb.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'Name',
		components: {
			Header,
			Footer,
			Breadcrumb,
			InviteTeam
		},
		data() {
			return {

			}
		},
		computed: {

		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.InviteTeam {
		position: relative;
		background-color: #000000;
		color: #ffffff;
	}
</style>