<template>
	<div class="inviteTeam">
		<h1>Congratulations</h1>
		<h3>You are almost there to claim the Star-Studded Captain Medal </h3>

		<div class="video-box">
			<video class="video" ref="video" :controls="false" :src="videoNum" autoplay :width="'100%'" muted
				:height="'100%'" webkit-playsinline playsinline x5-playsinline loop></video>
		</div>
		<div class="progress flex-center" v-if="totalCount<5">
			<ul>
				<li v-for="item in 25" v-if="item <= progress"></li>
			</ul>
			<span>{{progressNum}}%</span>
		</div>
		<div class="btn-box flex-column flex-center">
			<a-button type="primary" @click="isShare = true">
				<span>Invite one team member</span>
			</a-button>
			<a-button type="primary" @click="$router.push('/Dashboard')">
				<span>Go to dashboard</span>
			</a-button>
		</div>

		
		<modal class="share-popup" v-model="isShare">
			<div class="share">
				<h2>
					Share your invite code
				</h2>
				<h2>
					to invite one team member to join the bridge campaign !
				</h2>
				<h4>
					(Both of you will get 1,000 points)
				</h4>
				<p>
					<span>{{invcode}}</span>
				</p>
				<div class="btn-box flex-column flex-center">
					<a-button type="primary" v-copy="'https://www.wod.ai/InvLogin?inviteCode='+invcode">
						<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/copy.png" alt="" />
						<span>Copy invite link</span>
					</a-button>
					<a-button type="primary" @click="twStAuth">
						<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/twitter-icon1.png" alt="" />
						<span>Post a twitter</span>
					</a-button>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
	import { getteaminfo, getusertoast } from '@/utils/active.api'
	import modal from '@/components/modal.vue'
	import { twStAuth } from '@/utils/twitter.api'
	import { mapState } from 'vuex'
	export default {
		name: 'Name',
		components: { modal },
		data() {
			return {
				isShare: false,
				totalCount: 0,
				invcode: '',
				timeS: '',
				msg: {},
			}

		},
		computed: {
			...mapState(['addressinfo', 'address']),
			videoNum() {
				switch (this.totalCount) {
					case 0:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations-not.mp4';
					case 1:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations1.mp4';
					case 2:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations2.mp4';
					case 3:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations3.mp4';
					case 4:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations4.mp4';
					case 5:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations5.mp4';
					case 6:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations6.mp4';
					default:
						return 'https://wami-wod.oss-cn-beijing.aliyuncs.com/img/invitations-not.mp4';
				}
			},
			progressNum() {
				switch (this.totalCount) {
					case 0:
						return 80;
					case 1:
						return 90;
					case 2:
						return 95;
					case 3:
						return 99;
					case 4:
						return 100;
					case 5:
						return 999;
					case 6:
						return 999;
					default:
						return 80;
				}
			},
			progress() {
				if (this.progressNum > 100) return 100
				return 25 * this.progressNum / 100
			},
		},
		beforeDestroy() {
			clearInterval(this.timeS)
		},
		//离开页面清除定时器失效问题
		beforeRouteLeave(to, from, next) {
			clearInterval(this.timeS)
			next()
		},
		mounted() {
			this.init();
		},
		watch: {
			address(newValue, oldValue) {
				clearInterval(this.timeS)
				this.init();
			},
		},
		methods: {
			init() {
				this.getteaminfo();
				this.timeS = setInterval(() => {
					this.getteaminfo();
					this.getusertoast();
				}, 2000)

			},
			//发布推特
			async twStAuth() {
				const res = await twStAuth(this.myTeam['invcode']);
				window.open(res.data.url);
			},
			async getusertoast() {
				const { success, list } = await getusertoast({
					pageSize: 1,
					pageIndex: 1,
				})
				const nowMsg = list[0]
				if (!nowMsg['idx']) return
				this.msg = nowMsg;
				if (!this.msg['idx']) return
				if (this.msg['idx'] != nowMsg['idx']) {
					this.$message.info(nowMsg['toast'])
				}
			},
			//团队成员
			async getteaminfo() {

				const { success, data, msg } = await getteaminfo(this.teamleader)
				if (success) {
					this.totalCount = Number(data.membercount);
					this.invcode = data.invcode;
				}



			},
		}
	}
</script>

<style lang="scss" scoped>
	.inviteTeam {
		text-align: center;
		padding: 60px 0;

		h1 {
			position: relative;
			z-index: 9;
			color: #FFF;
			
			font-size: 42px;
			font-style: normal;
			font-weight: 700;
			line-height: 42px;
			/* 100% */
		}

		h3 {
			position: relative;
			z-index: 9;
			padding-top: 10px;
			color: #FFF;
			
			font-size: 16px;
			font-style: normal;
			font-weight: 510;
			line-height: 16px;
			/* 100% */
		}

		.video-box {
			margin: -30px 0 -100px;
			position: relative;
			z-index: 0;

			.video {
				width: 640px;
				height: 640px;
				position: relative;
				margin: 0 auto;
			}
		}

		.progress {
			position: relative;
			z-index: 9;

			ul {
				border: 1px solid #29FF7E;
				background: #000002;
				box-shadow: 0px 0px 16px 0px rgba(41, 255, 126, 0.20);

				width: 350px;
				padding: 3px;
				margin: 0;
				display: grid;
				grid-template-columns: repeat(25, 1fr);
				grid-gap: 2px;
				align-items: center;
				justify-content: flex-start;

				li {
					margin: 0;
					height: 4px;
					flex-shrink: 0;
					border: 1px solid #29FF7E;
					background: #00E25A;
					box-shadow: 0px 0px 16px 0px #29FF7E;
				}
			}

			span {
				padding-left: 15px;
				color: #29FF7E;
				font-family: DIGIB;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;
				/* 100% */
			}
		}

		.btn-box {
			padding-top: 20px;

			.ant-btn {
				width: 480px;
				height: 52px;
				border: unset;
				border-radius: 12px;
				background: var(--2, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));

				color: #FFF;
				
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 20px;
				/* 100% */
			}

			.ant-btn:nth-of-type(2) {
				margin-top: 20px;
				background: unset;
			}
		}

		@screen md {
			.video-box {
				margin: -30px 0 -20px;

				.video {
					width: 100%;
					height: 100%;
				}
			}

			.progress {
				ul {
					width: 220px;
				}
			}

			.btn-box {
				.ant-btn {
					width: 240px;

					color: #FFF;
					
					font-size: 14px;
					font-style: normal;
					font-weight: 590;
					line-height: 14px;
					/* 100% */
				}
			}
		}
	}

	.share-popup {

		.share {
			width: 860px;
			text-align: center;

			h2 {
				margin: 0;
				color: rgba(255, 255, 255, 0.80);
				text-align: center;
				font-feature-settings: 'clig' off, 'liga' off;
				
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 40px;
			}

			h4 {
				margin: 0;
				padding-top: 8px;
				color: #93FEFE;
				text-align: center;
				font-feature-settings: 'clig' off, 'liga' off;
				
				font-size: 24px;
				font-style: normal;
				font-weight: 3;
				line-height: 40px;
				/* 166.667% */
			}

			p {
				margin: 0;
				padding-top: 48px;
				padding-bottom: 32px;
				color: #FFF;
				font-feature-settings: 'clig' off, 'liga' off;
				
				font-size: 64px;
				font-style: normal;
				font-weight: 590;
				line-height: 64px;
				/* 100% */
				letter-spacing: 24px;
			}

			.btn-box {
				.ant-btn {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 12px;
					height: 42px;

					span {
						padding-left: 10px;
						color: #FFF;
						text-align: right;
						
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: 16px;
						/* 100% */
					}
				}

				.ant-btn:nth-of-type(2) {
					border-radius: 12px;
					background: #242424;
				}
			}
		}

		@screen md {
			.share {
				padding-top: 25px;
				width: unset;

				h2 {
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px;
					/* 125% */
				}

				h4 {
					font-size: 16px;
				}

				p {
					padding-left: 20px;
					text-align: center;
					font-size: 26px;
					font-style: normal;
					font-weight: 590;
				}

				.btn-box {
					.ant-btn {
						width: 273px;
						height: 42px;
						border-radius: 8px;

						span {}
					}
				}
			}
		}
	}
</style>
<style>
	.ant-message-custom-content {
		display: flex;
		align-items: center;
		width: 290px;

		span {
			flex: 1;
			display: inline-block;
			word-break: break-all;
			color: #FFF;
			color: #93FEFE;
			
			font-size: 12px;
			text-align: left;
			/* 100% */
		}
	}
</style>